@layer modules, ui, base;
@layer ui {
  /** @define ElementGrid */

.elementGrid_jds-ElementGrid__1R7_G {
  --ElementGrid-columnsCount: 1;

  display: grid;
  gap: var(--Layout-column-gutter-size);
  grid-template-columns: repeat(var(--ElementGrid-columnsCount), minmax(0, 1fr));
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 48rem) {
  .elementGrid_jds-ElementGrid__1R7_G {
    --ElementGrid-columnsCount: 2;
  }
}

@media (min-width: 64rem) {
  .elementGrid_jds-ElementGrid__1R7_G {
    --ElementGrid-columnsCount: 3;
  }

  .elementGrid_jds-ElementGrid--2Cols__doh_u {
    --ElementGrid-columnsCount: 2;
  }

  .elementGrid_jds-ElementGrid--4Cols__RREmA {
    --ElementGrid-columnsCount: 2;
  }
}

@media (min-width: 80rem) {
  .elementGrid_jds-ElementGrid--4Cols__RREmA {
    --ElementGrid-columnsCount: 4;
  }
}

}
